import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useParams } from 'react-router-dom';
import { getConsultorById, ConsultorData } from '@/store/consultores/consultoresActions';
import { parseCopete } from '@/components/utils';
import { getLibros } from '@/store/libros/librosActions';
import LibroForm from '@/components/organisms/LibroForm';

const Consultor: React.FC = () => {
  const { consultorId } = useParams();
  const dispatch = useAppDispatch();
  const libros = useAppSelector(state => state.libros?.itemsData);
  const dataConsultor = useAppSelector(state => state.consultores?.consultorIdData);
  localStorage.setItem('consultores', 'true');
  const [paso, setPaso] = useState(1);
  const [resetKey, setResetKey] = useState(0);
  const [resetFormState, setResetFormState] = useState(false);

  useEffect(() => {
    if (consultorId) {
      dispatch(getConsultorById({ idConsultor: parseInt(consultorId.split('-')[0]) }));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getLibros({}));
  }, [dispatch]);

  function handleSubmit(): void {
    // throw new Error('Function not implemented.');
  }

  const volverLista = () => {
    setResetFormState(prev => !prev); // Cambia el estado para forzar el reset
    setPaso(2);
  };

  function Carousel() {
    const librosArray = libros?.data;
    if (!Array.isArray(librosArray)) {
      // Si libros no es un array, mostrar un mensaje de error
      return <div></div>;
    }

    const groupsOfThree = librosArray.reduce<Array<Array<any>>>((acc, item, index) => {
      const groupIndex = Math.floor(index / 3);

      if (!acc[groupIndex]) {
        acc[groupIndex] = [];
      }

      acc[groupIndex].push(item);

      return acc;
    }, []);

    return (
      <div className="carousel-inner">
        {groupsOfThree.map((group: any, index: any) => (
          <div key={`libro-g-${index}`} className={`carousel-item ${index === 0 ? 'active' : ''}`} data-bs-interval={10000}>
            <div className="row mb-5">
              {group.map((item: any) => (
                <div key={`libro-${item.id}`} className="col-md-4 text-center column-border-end">
                  <article className="px-4">
                    <a href="#">
                      <img src={item.imagen} alt={item.nombre} className="img-fluid" />
                      <h2>{item.nombre}</h2>
                      <h6 className="text-secondary">{item.autor}</h6>
                      <div>
                        <strong> $ {item.precio}</strong>
                      </div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.descripcion.length > 120 ? item.descripcion.substring(0, 120) + '...' : item.descripcion,
                        }}></p>
                    </a>
                  </article>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <>
      {/* Breadcrumb */}
      <section className="container d-none d-lg-block mt-2">
        <div className="row">
          <div className="col-12">
            <div className="border-top py-2">
              <nav style={{}} aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Inicio</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/consultores">Consultores</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {dataConsultor ? dataConsultor.Nombre : ''}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* Header */}
      <section style={{ marginBottom: '0px !important' }} className="container my-2 my-md-5">
        <div className="row g-4 border-bottom">
          <div className="col-4 col-md-1">
            <img src={dataConsultor?.perfil_foto} className="border border-1 rounded-circle img-fluid" alt="" />
          </div>
          {dataConsultor && (
            <>
              <div className="col-md-6 text-start">
                <h1>{dataConsultor.Nombre}</h1>
                <ul className="profile-info">
                  <li className="icon-location d-flex align-items-center my-1">
                    {dataConsultor.localidad ? dataConsultor.localidad.Nombre : ''},{' '}
                    {dataConsultor.provincia ? dataConsultor.provincia.Nombre : ''},{' '}
                    {dataConsultor.pais ? dataConsultor.pais.Nombre : ''}
                  </li>
                  <li className="icon-phone d-flex align-items-center my-1">{dataConsultor.Telefono}</li>
                  <li className="icon-mail d-flex align-items-center my-1">{dataConsultor.Email}</li>
                </ul>
              </div>
              {paso === 1 && (
                <>
                  {dataConsultor.perfil_presentacion && (
                    <>
                      <h1>Conóceme</h1>
                      <div className="col-12" style={{ marginTop: '0px' }}>
                        <p className="lead" dangerouslySetInnerHTML={{ __html: dataConsultor.perfil_presentacion }} />
                      </div>
                    </>
                  )}
                  <h1>Mis Certificaciones</h1>
                  <div className="col-12 ">
                    {dataConsultor &&
                      dataConsultor.certificados?.map(certificado => (
                        <a className="btn btn-outline-primary me-1 mb-2" href="#" key={certificado.Nombre} role="button">
                          {certificado.Nombre}
                        </a>
                      ))}
                  </div>
                  {dataConsultor.perfil_activo === 1 && (
                    <>
                      <h1>Productos</h1>
                      <p className="lead " style={{ marginTop: '0px' }}>
                        {' '}
                        En el siguiente enlace, puedes adquirir los productos de Humano Puente.
                      </p>
                      <button className="btn btn-primary" onClick={() => setPaso(2)}>
                        Adquirir Productos
                      </button>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </section>
      {/* Libros */}
      {paso === 2 && (
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div>
                  <div className="row py-3">
                    <div className="col-6">
                      <h5 className="text-primary align-middle">
                        <strong>Aquí puedes conocer los libros y sus valores</strong>
                      </h5>
                    </div>
                    <div className="col-6 text-end">
                      <button
                        type="button"
                        className="btn btn-link btn-lg"
                        data-bs-target="#carouselExampleDark"
                        data-bs-slide="prev">
                        <i className="bi bi-chevron-left" />
                      </button>
                      <button
                        type="button"
                        className="btn btn-link btn-lg"
                        data-bs-target="#carouselExampleDark"
                        data-bs-slide="next">
                        <i className="bi bi-chevron-right" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
                  <div className="carousel-indicators">
                    <button
                      type="button"
                      data-bs-target="#carouselExampleDark"
                      data-bs-slide-to={0}
                      className="active"
                      aria-current="true"
                      aria-label="Slide 1"
                    />
                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to={1} aria-label="Slide 2" />
                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to={2} aria-label="Slide 3" />
                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to={3} aria-label="Slide 4 " />
                  </div>
                  {libros && Carousel()}
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <h6 className="text-secondary">COSTO DE ENVIO </h6>

                      <strong>Los valores incluyen entrega a toda la Argentina</strong>
                    </div>
                  </div>
                </div>
              </div>
              <p className="mb-3 mt-3">
                Estos son mis datos de contacto y opciones de pago. Para realizar tu compra, selecciona el artículo y copia los
                datos necesarios para la transferencia. RECORDA GUARDAR EL COMPROBANTE DE TRANSFERENCIA.
              </p>
            </div>

            <div className="row g-4 d-flex align-items-stretch">
              <div className="col-md-6  d-flex">
                <div className="card  card-bg-02  flex-fill">
                  <div className="card-body">
                    <h6 className="text-secondary">Datos Personales</h6>
                    <p className="icon-location d-flex align-items-center my-1">
                      {dataConsultor?.localidad ? dataConsultor.localidad.Nombre : ''},{' '}
                      {dataConsultor?.provincia ? dataConsultor.provincia.Nombre : ''},{' '}
                      {dataConsultor?.pais ? dataConsultor.pais.Nombre : ''}
                    </p>
                    <p className="icon-phone d-flex align-items-center my-1">{dataConsultor?.Telefono}</p>
                    <p className="icon-mail d-flex align-items-center my-1">{dataConsultor?.Email}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex">
                <div className="card card-bg-03  flex-fill">
                  <div className="card-body ">
                    <h6 className="text-secondary">Opciones de Pago</h6>
                    {dataConsultor?.perfil_datosbancarios && (
                      <p dangerouslySetInnerHTML={{ __html: dataConsultor?.perfil_datosbancarios }} />
                    )}
                  </div>
                </div>
              </div>
              <p className="mb-3 mt-3">
                <strong>Una vez realizada la transferencia por favor realiza el click Completar Formulario</strong>
              </p>
              <div className="d-flex justify-content-end gap-3 mb-5">
                <button className="btn btn-outline-primary  w-50" onClick={() => setPaso(1)}>
                  Volver al Pefil del Consultor
                </button>
                <button
                  className="btn btn-primary  w-50"
                  onClick={() => {
                    setPaso(3);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}>
                  Completar Formulario
                </button>
              </div>
            </div>
          </div>
        </section>
      )}

      {paso === 3 && (
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <LibroForm key={resetKey} dataConsultor={dataConsultor} reset={resetFormState}></LibroForm>
              </div>
            </div>
            <div className=" mb-5">
              <button className="btn btn-outline-primary  w-100" onClick={volverLista}>
                Volver a la lista de productos
              </button>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Consultor;
