import { createAsyncThunk } from '@reduxjs/toolkit';
import { ValidationErrors } from '@/interfaces/common';
import { axiosData } from '@/axios';

import type { AxiosError, AxiosResponse } from 'axios';

const modulePrefix = '/consultores';

export interface ConsultorData {
  IdConsultor: number;
  Nombre: string;
  Telefono: string;
  Email: string;
  IdPais: number;
  IdProvincia: number;
  IdLocalidad: number;
  Decodificacion: number;
  Reoris: number;
  CodigoAreaTelefono: string;
  Skype: number;
  IdEstado: number;
  Comentarios: string;
  localidad: ConsultorGenericData;
  pais: ConsultorGenericData;
  provincia: ConsultorGenericData;
  certificados: ConsultorGenericData[];
  perfil_activo: number;
  perfil_presentacion: string;
  perfil_foto: string;
  perfil_datosbancarios: string;
}

export interface ConsultorEData {
  id: number;
  user_id: string;
  name: string;
  lastname: string;
  email: string;
  phone: string;
  localidad: string;
  provincia: string;
  country: string;
  idioma: string;
}

export interface ConsultorGenericData {
  Nombre: string;
}

export interface ConsultoresResponse {
  data: ConsultorData[];
  /* largoTotal: number;
  paginaActual: number;
  totalPaginas: number;*/
}

export interface ConsultoresEResponse {
  data: ConsultorEData[];
  /* largoTotal: number;
  paginaActual: number;
  totalPaginas: number;*/
}

export interface ConsultoresProps {
  page?: number;
  busqueda?: string;
  idConsultor?: number;
  IdPais?: string;
  Nombre?: string;
  IdLocalidad?: string;
  IdProvincia?: string;
  actividad?: string;
  idioma?: string;
  tipo?: string;
}

export const getConsultores = createAsyncThunk(
  `${modulePrefix}/getConsultores`,
  async ({ page, IdPais, Nombre, IdLocalidad, IdProvincia, actividad, idioma, tipo }: ConsultoresProps, { rejectWithValue }) => {
    try {
      let url = '/consultores';
      if (actividad != '' && actividad != undefined) url = url + '/certificados/' + actividad;
      url = url + '?includes=pais,provincia,localidad&limit=5000&IdEstado=1';

      if (idioma != '' && idioma != undefined) url = url + '&idioma=' + idioma;
      if (tipo != '' && tipo != undefined) url = url + '&tipo=' + tipo;
      if (IdPais != '' && IdPais != undefined) url = url + '&IdPais=' + IdPais;
      if (IdProvincia != '' && IdProvincia != undefined) url = url + '&IdProvincia=' + IdProvincia;
      if (Nombre != '' && Nombre != undefined) url = url + '&Nombre=*' + Nombre + '*';
      if (IdLocalidad != '' && IdLocalidad != undefined) url = url + '&IdLocalidad=' + IdLocalidad;

      const data: AxiosResponse = await axiosData.get(url, {});
      const result: ConsultoresResponse = data.data;
      return result;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

export const getConsultoresE = createAsyncThunk(
  `${modulePrefix}/getConsultoresE`,
  async ({ page, busqueda }: ConsultoresProps, { rejectWithValue }) => {
    try {
      const data: AxiosResponse = await axiosData.get(`/consultoresforo?paginate=1500`, {
        params: { page },
      });
      const result: ConsultoresEResponse = data.data;
      return result;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

export const getConsultorById = createAsyncThunk(
  `${modulePrefix}/getConsultorById`,
  async ({ idConsultor }: ConsultoresProps, { rejectWithValue }) => {
    try {
      const data: AxiosResponse = await axiosData.get(
        `consultores/${idConsultor!.toString()}?includes=certificados,pais,localidad,provincia`,
        {},
      );

      const result: ConsultorData = data.data.item;
      return result;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);
