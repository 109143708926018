import { createSlice, PayloadAction, TaskAbortError } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import { UserPayload, UserInfo, } from '@/interfaces/user';
import {
  contactUser,
  encuestaEmpresa,
  createUser,
  loginUser,
  logoutUser,
  GenericResponse, 
  refreshTokenAction,
  empresaUser,
  newsletterUser,
  contactCertificado,
  contactFormarParte,
  compraLibros,
} from './userActions';
import { axiosPrivate, axiosAuthPrivate } from '@/axios';

interface InitialState {
  loading: boolean;
  userInfo: UserInfo | null | undefined;
  accessToken: string | null;
  refreshToken: string | null;
  rememberMe: boolean;
  error: string | null | undefined;
  success: boolean;
  adminUserError: boolean;
  adminUserLoading: boolean;
  newUserLoading: boolean;
  newUserError: boolean;
  newUserSuccess: boolean;
  editUserSuccess: boolean;
  editUserError: boolean;
  editUserLoading: boolean;
  resetPasswordError: boolean;
  resetPasswordSuccess: boolean;
  resetPasswordLoading: boolean;
  deleteSuccess: boolean;
  deleteError: boolean;
  deletePending: boolean;

  newContactLoading: boolean;
  newContactError: boolean;
  newContactSuccess: boolean;


  newContactCertificadoLoading: boolean;
  newContactCertificadoError: boolean;
  newContactCertificadoSuccess: boolean;


  newlibroLoading: boolean;
  newlibroError: boolean;
  newlibroSuccess: boolean;

  newContactFormarParteLoading: boolean;
  newContactFormarParteError: boolean;
  newContactFormarParteSuccess: boolean;

  newEmpresaLoading: boolean;
  newEmpresaError: boolean;
  newEmpresaSuccess: boolean;

  newNewsletterLoading: boolean;
  newNewsletterError: boolean;
  newNewsletterSuccess: boolean;

}
interface LogoutResponse {
  data: string;
  status: number;
  statusText: string;
}
 

// initialize userToken from local or session storage
const accessToken = localStorage.getItem('accessToken')
  ? localStorage.getItem('accessToken')
  : sessionStorage.getItem('accessToken')
  ? sessionStorage.getItem('accessToken')
  : null;

if (accessToken) {
  axiosPrivate.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  axiosAuthPrivate.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
}
// initialize refreshToken from local or session storage
const refreshToken = localStorage.getItem('refreshToken')
  ? localStorage.getItem('refreshToken')
  : sessionStorage.getItem('refreshToken')
  ? sessionStorage.getItem('refreshToken')
  : null;

const userInfo: UserInfo | null = accessToken ? jwt_decode(accessToken) : null;

const initialState: InitialState = {
  loading: false,
  userInfo, // for user object
  accessToken,
  refreshToken,
  rememberMe: false,
  error: null,
  success: false, // for monitoring the registration process.
  adminUserLoading: false,
  adminUserError: false,
  newUserError: false,
  newUserLoading: false,
  newUserSuccess: false,
  editUserError: false,
  editUserLoading: false,
  editUserSuccess: false,
  resetPasswordError: false,
  resetPasswordLoading: false,
  resetPasswordSuccess: false,
  deleteError: false,
  deletePending: false,
  deleteSuccess: false,

  newContactError: false,
  newContactLoading: false,
  newContactSuccess: false,

  newlibroError: false,
  newlibroLoading: false,
  newlibroSuccess: false,

  newContactCertificadoError: false,
  newContactCertificadoLoading: false,
  newContactCertificadoSuccess: false,

  newContactFormarParteError: false,
  newContactFormarParteLoading: false,
  newContactFormarParteSuccess: false,

  newEmpresaError: false,
  newEmpresaLoading: false,
  newEmpresaSuccess: false,

  newNewsletterError: false,
  newNewsletterLoading: false,
  newNewsletterSuccess: false,

};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: () => {
      return { ...initialState };
    },
    resetUserCreate: state => {
      return { ...state, newUserSuccess: false, newUserError: false, newUserLoading: false };
    },
    resetMessageState: state => {
      return {
        ...state,
        deleteError: false,
        deleteSuccess: false,
        editUserError: false,
        editUserSuccess: false,
        resetPasswordError: false,
        resetPasswordSuccess: false,
      };
    },
  },
  extraReducers: builder => {
    // login user
    builder.addCase(loginUser.pending, (state: InitialState) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(loginUser.fulfilled, (state: InitialState, { payload }: PayloadAction<UserPayload>) => {
      state.loading = false;
      state.success = true;
      state.userInfo = payload.userInfo;
      state.accessToken = payload.accessToken;
      state.error = null;
      if (payload.refreshToken) state.refreshToken = payload.refreshToken;
      if (payload.rememberMe) state.rememberMe = payload.rememberMe;
    });
    builder.addCase(loginUser.rejected, (state: InitialState, action) => {
      state.loading = false;
      state.success = false;
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    });
    // logout user
    builder.addCase(logoutUser.pending, (state: InitialState) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(logoutUser.fulfilled, (state: InitialState, { payload }: PayloadAction<LogoutResponse>) => {
      state.loading = false;
      state.error = null;
      state.success = payload.status === 200;
      if (payload.status === 200) {
        state.userInfo = null;
        state.accessToken = null;
        state.refreshToken = null;
        state.rememberMe = false;
      }
    });
    builder.addCase(logoutUser.rejected, (state: InitialState, action) => {
      state.loading = false;
      state.success = false;
      state.userInfo = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.rememberMe = false;
    });
    // refresh token
    builder.addCase(refreshTokenAction.pending, (state: InitialState) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(refreshTokenAction.fulfilled, (state: InitialState, { payload }: PayloadAction<UserPayload>) => {
      state.userInfo = payload.userInfo;
      state.accessToken = payload.accessToken;
      state.success = true;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(refreshTokenAction.rejected, (state: InitialState, action) => {
      state.loading = false;
      state.success = false;
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    });
    builder.addCase(createUser.rejected, (state: InitialState, action) => {
      state.newUserError = true;
      state.newUserLoading = false;
      state.newUserSuccess = false;
    });
    builder.addCase(createUser.pending, (state: InitialState) => {
      state.newUserLoading = true;
      state.newUserError = false;
      state.newUserSuccess = false;
    });
    builder.addCase(createUser.fulfilled, (state: InitialState, { payload }: PayloadAction<UserPayload>) => {
      state.loading = false;
      state.success = true;
      state.userInfo = payload.userInfo;
      state.accessToken = payload.accessToken;
      state.error = null;
      if (payload.refreshToken) state.refreshToken = payload.refreshToken;
      if (payload.rememberMe) state.rememberMe = payload.rememberMe;
    });


    builder.addCase(contactUser.rejected, (state: InitialState, action) => {
      state.newContactError = true;
      state.newContactLoading = false;
      state.newContactSuccess = false;
    });
    builder.addCase(contactUser.pending, (state: InitialState) => {
      state.newContactLoading = true;
      state.newContactError = false;
      state.newContactSuccess = false;
    });
    builder.addCase(contactUser.fulfilled, (state: InitialState, { payload }: PayloadAction<GenericResponse>) => {
      state.newContactLoading = false;
      state.newContactSuccess = true;
      state.newContactError = false;
    });



    builder.addCase(contactCertificado.rejected, (state: InitialState, action) => {
      state.newContactCertificadoError = true;
      state.newContactCertificadoLoading = false;
      state.newContactCertificadoSuccess = false;
    });
    builder.addCase(contactCertificado.pending, (state: InitialState) => {
      state.newContactCertificadoLoading = true;
      state.newContactCertificadoError = false;
      state.newContactCertificadoSuccess = false;
    });
    builder.addCase(contactCertificado.fulfilled, (state: InitialState, { payload }: PayloadAction<GenericResponse>) => {
      state.newContactCertificadoLoading = false;
      state.newContactCertificadoSuccess = true;
      state.newContactCertificadoError = false;
    });


    builder.addCase(contactFormarParte.rejected, (state: InitialState, action) => {
      state.newContactFormarParteError = true;
      state.newContactFormarParteLoading = false;
      state.newContactFormarParteSuccess = false;
    });
    builder.addCase(contactFormarParte.pending, (state: InitialState) => {
      state.newContactFormarParteLoading = true;
      state.newContactFormarParteError = false;
      state.newContactFormarParteSuccess = false;
    });
    builder.addCase(contactFormarParte.fulfilled, (state: InitialState, { payload }: PayloadAction<GenericResponse>) => {
      state.newContactFormarParteLoading = false;
      state.newContactFormarParteSuccess = true;
      state.newContactFormarParteError = false;
    });
  
    builder.addCase(empresaUser.rejected, (state: InitialState, action) => {
      state.newEmpresaError = true;
      state.newEmpresaLoading = false;
      state.newEmpresaSuccess = false;
    });
    builder.addCase(empresaUser.pending, (state: InitialState) => {
      state.newEmpresaLoading = true;
      state.newEmpresaError = false;
      state.newEmpresaSuccess = false;
    });
    builder.addCase(empresaUser.fulfilled, (state: InitialState, { payload }: PayloadAction<GenericResponse>) => {
      state.newEmpresaLoading = false;
      state.newEmpresaSuccess = true;
      state.newEmpresaError = false;
    });

    builder.addCase(newsletterUser.rejected, (state: InitialState, action) => {
      state.newNewsletterError = true;
      state.newNewsletterLoading = false;
      state.newNewsletterSuccess = false;
    });
    builder.addCase(newsletterUser.pending, (state: InitialState) => {
      state.newNewsletterLoading = true;
      state.newNewsletterError = false;
      state.newNewsletterSuccess = false;
    });
    builder.addCase(newsletterUser.fulfilled, (state: InitialState, { payload }: PayloadAction<GenericResponse>) => {
      state.newNewsletterLoading = false;
      state.newNewsletterSuccess = true;
      state.newNewsletterError = false;
    });



    builder.addCase(compraLibros.rejected, (state: InitialState, action) => {
      state.newlibroError = true;
      state.newlibroLoading = false;
      state.newlibroSuccess = false;
    });
    builder.addCase(compraLibros.pending, (state: InitialState) => {
      state.newlibroLoading = true;
      state.newlibroError = false;
      state.newlibroSuccess = false;
    });
    builder.addCase(compraLibros.fulfilled, (state: InitialState, { payload }: PayloadAction<GenericResponse>) => {
      state.newlibroLoading = false;
      state.newlibroSuccess = true;
      state.newlibroError = false;
    });


    builder.addCase(encuestaEmpresa.rejected, (state: InitialState, action) => {
      state.newContactError = true;
      state.newContactLoading = false;
      state.newContactSuccess = false;
    });
    builder.addCase(encuestaEmpresa.pending, (state: InitialState) => {
      state.newContactLoading = true;
      state.newContactError = false;
      state.newContactSuccess = false;
    });
    builder.addCase(encuestaEmpresa.fulfilled, (state: InitialState, { payload }: PayloadAction<GenericResponse>) => {
      state.newContactLoading = false;
      state.newContactSuccess = true;
      state.newContactError = false;
    });
    
  },
});

export const { reset, resetUserCreate, resetMessageState } = userSlice.actions;

export default userSlice.reducer;
