import { createAsyncThunk, EmptyObject } from '@reduxjs/toolkit';
import {
  LoginUserProps,
  UserPayload,
  UserInfo,
  ContactoUserProps,
  NewsletterUserProps,
  EmpresaUserProps,
  EncuestaEmpresaProps,
  RecoveryProps,
  CompraLibroProps,
} from '@/interfaces/user';
import { ValidationErrors } from '@/interfaces/common';
import jwt_decode from 'jwt-decode';
import { axiosPrivate, axiosAuth, axiosAuthPrivate, axiosData } from '@/axios';
import type { AxiosError, AxiosResponse } from 'axios';
import { ValidationError } from 'yup';
import { NewUserValues } from '@/components/organisms/CreateUser';
import { COMPRA_USER_DETALLE } from '@/components/constants';

export interface GenericResponse {
  message: string;
}

interface DataResponse {
  access_token: string;
  refresh_token: string;
}
interface LoginResponse {
  data: DataResponse;
}
interface RefreshTokenProps {
  refreshTokenID: number;
  refreshToken: string;
}
interface LogoutResponse {
  data: string;
  status: number;
  statusText: string;
}

export interface UserProps {
  id: number;
  nombre: string;
  apellido: string;
  email: string;
  creadoEn: string;
  rol: string;
}

const modulePrefix = 'user';

export const loginUser = createAsyncThunk<
  // Return type of the payload creator
  UserPayload,
  // First argument to the payload creator
  LoginUserProps,
  // Types for error response
  {
    rejectValue: ValidationErrors; // Error type
  }
>(
  // action type string
  `${modulePrefix}/login`,
  // callback function
  async (
    {
      Email,
      Contrasenia,
      rememberMe,
      OS,
      Browser,
      Language,
      TimeZone,
      ScreenPrint,
      LocalStorage,
      CPU,
      OSVersion,
    }: LoginUserProps,
    { rejectWithValue },
  ) => {
    try {
      // make request to backend

      const {
        data: { access_token: accessToken, refresh_token: refreshToken },
      }: LoginResponse = await axiosAuth.post('/login', {
        Email,
        Contrasenia,
        OS,
        Browser,
        Language,
        TimeZone,
        ScreenPrint,
        LocalStorage,
        CPU,
        OSVersion,
      });

      axiosPrivate.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      axiosAuthPrivate.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

      const userInfo: UserInfo = jwt_decode(accessToken);

      // store user's token in local storage
      if (rememberMe && accessToken && refreshToken) {
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
      } else if (accessToken && refreshToken) {
        sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
        sessionStorage.setItem('accessToken', accessToken);
        sessionStorage.setItem('refreshToken', refreshToken);
      }

      return { userInfo, accessToken, refreshToken, rememberMe };
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  },
);

export const refreshTokenAction = createAsyncThunk<
  // Return type of the payload creator
  UserPayload,
  // First argument to the payload creator
  RefreshTokenProps,
  // Types for error response
  {
    rejectValue: ValidationErrors; // Error type
  }
>(
  // action type string
  `${modulePrefix}/refreshToken`,
  // callback function
  async ({ refreshTokenID, refreshToken: refreshTokenParam }: RefreshTokenProps, { rejectWithValue }) => {
    try {
      // make request to backend

      const {
        data: { access_token: accessToken },
      }: LoginResponse = await axiosAuth.post('/refresh-token', { refreshTokenID, refreshToken: refreshTokenParam });

      const userInfo: UserInfo = jwt_decode(accessToken);

      // store user's token in local storage
      if (localStorage.getItem('userInfo') && accessToken) {
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        localStorage.setItem('accessToken', accessToken);
      } else if (accessToken) {
        sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
        sessionStorage.setItem('accessToken', accessToken);
      }

      return { userInfo, accessToken };
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  },
);

export const logoutUser = createAsyncThunk<
  // Return type of the payload creator
  LogoutResponse,
  // First argument to the payload creator
  EmptyObject,
  // Types for error response
  {
    rejectValue: ValidationErrors; // Error type
  }
>(
  // action type string
  `${modulePrefix}/logout`,
  // callback function
  async (_, { rejectWithValue }) => {
    try {
      // make request to backend
      const { data, status, statusText }: LogoutResponse = await axiosAuthPrivate.post('/logout');

      // remove local storage
      if (localStorage.getItem('userInfo')) {
        localStorage.removeItem('userInfo');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
      } else {
        sessionStorage.removeItem('userInfo');
        sessionStorage.removeItem('accessToken');
        sessionStorage.removeItem('refreshToken');
      }

      localStorage.removeItem(COMPRA_USER_DETALLE);
      window.location.href = '/';

      return { data, status, statusText };
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  },
);

export const createUser = createAsyncThunk(`${modulePrefix}/register`, async (data: NewUserValues, { rejectWithValue }) => {
  try {
    const {
      data: { access_token: accessToken, refresh_token: refreshToken },
    }: LoginResponse = await axiosAuthPrivate.post('/register', data);

    axiosPrivate.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axiosAuthPrivate.defaults.headers.common.Authorization = `Bearer ${refreshToken}`;

    const userInfo: UserInfo = jwt_decode(accessToken);

    sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
    sessionStorage.setItem('accessToken', accessToken);
    sessionStorage.setItem('refreshToken', refreshToken);

    return { userInfo, accessToken, refreshToken };
  } catch (err: any) {
    const error: AxiosError<ValidationErrors> = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});

export const codeUser = createAsyncThunk(`${modulePrefix}/code`, async (data: LoginUserProps, { rejectWithValue }) => {
  try {
    const DataResponse = await axiosAuthPrivate.post('/forgot-password', data);

    return {};
  } catch (err: any) {
    const error: AxiosError<ValidationErrors> = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});

export const code2User = createAsyncThunk(`${modulePrefix}/code2`, async (data: RecoveryProps, { rejectWithValue }) => {
  try {
    const dataresult = await axiosAuthPrivate.post('/forgot-password-login', data);

    return dataresult.data;
  } catch (err: any) {
    const error: AxiosError<ValidationErrors> = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});

export const contactUser = createAsyncThunk(`${modulePrefix}/contact`, async (data: ContactoUserProps, { rejectWithValue }) => {
  try {
    const { message: message }: GenericResponse = await axiosData.post('/contactos/send', data);
    return { message };
  } catch (err: any) {
    const error: AxiosError<ValidationErrors> = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});

export const compraLibros = createAsyncThunk(`${modulePrefix}/compraLibros`, async (data: FormData, { rejectWithValue }) => {
  try {
    const { message: message }: GenericResponse = await axiosData.post('cud/create-libro', data);
    return { message };
  } catch (err: any) {
    const error: AxiosError<ValidationErrors> = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});

export const encuestaEmpresa = createAsyncThunk(
  `${modulePrefix}/contact-empresa`,
  async (data: EncuestaEmpresaProps, { rejectWithValue }) => {
    try {
      const { message: message }: GenericResponse = await axiosData.post('/contactos/send-encuesta', data);
      return { message };
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

export const contactCertificado = createAsyncThunk(
  `${modulePrefix}/contactCertificado`,
  async (data: ContactoUserProps, { rejectWithValue }) => {
    try {
      const { message: message }: GenericResponse = await axiosData.post('/contactos/send-certificado', data);
      return { message };
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

export const contactFormarParte = createAsyncThunk(
  `${modulePrefix}/contactFormarParte`,
  async (data: ContactoUserProps, { rejectWithValue }) => {
    try {
      const { message: message }: GenericResponse = await axiosData.post('/contactos/send-formarparte', data);
      return { message };
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

export const empresaUser = createAsyncThunk(`${modulePrefix}/empresa`, async (data: EmpresaUserProps, { rejectWithValue }) => {
  try {
    const { message: message }: GenericResponse = await axiosData.post('/contactos/send-empresa', data);
    return { message };
  } catch (err: any) {
    const error: AxiosError<ValidationErrors> = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});

export const newsletterUser = createAsyncThunk(
  `${modulePrefix}/newsletter`,
  async (data: NewsletterUserProps, { rejectWithValue }) => {
    try {
      const { message: message }: GenericResponse = await axiosData.post('/contactos/send-newsletter', data);
      return { message };
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  },
);
