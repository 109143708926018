import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Alert, Button, Container, Form } from 'react-bootstrap';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { compraLibros, contactUser } from '../../store/user/userActions';

import { reset, resetUserCreate } from '@/store/user/userSlice';
import * as Yup from 'yup';
import TabNavigation from '../molecules/TabNavigation';
import { ConsultorData } from '@/store/consultores/consultoresActions';

export interface NewUserValues {
  nombre: string;
  telefono: string;
  email: string;
  archivo: File | null;
  idconsultor: number;
  calle: string;
  numero: string;
  piso: string;
  localidad: string;
  partido: string;
  provincia: string;
  codigopostal: string;

  libros: string;
}

interface FormProps {
  dataConsultor?: {
    Email?: string;
    Telefono?: string;
  };
  reset: boolean;
}

// eslint-disable-next-line react-hooks/rules-of-hooks

// eslint-disable-next-line @typescript-eslint/no-shadow
const LibroForm: React.FC<FormProps> = ({ dataConsultor, reset }) => {
  const initialValues: NewUserValues = {
    nombre: '',
    telefono: '',
    email: '',
    libros: '',
    archivo: null,
    idconsultor: 0,
    calle: '',
    numero: '',
    piso: '',
    localidad: '',
    partido: '',
    provincia: '',
    codigopostal: '',
  };
  const dispatch = useAppDispatch();
  const { newlibroError, newlibroLoading, newlibroSuccess } = useAppSelector(state => state.user);
  const [addError, setAddError] = useState(false);
  const [addSuccess, setAddSuccess] = useState(false);

  useEffect(() => {
    console.log('reset:' + addSuccess);

    setAddError(false);
    setAddSuccess(false);

    setTimeout(() => {
      setAddSuccess(false);
      setAddError(false);
    }, 0);
  }, [reset]);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Este campo es obligatorio')
      .email('Este campo tiene que ser un email')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    nombre: Yup.string()
      .required('Este campo es obligatorio')
      .matches(/^([^0-9]*)$/, 'Ingresa un formato correcto')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    telefono: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    calle: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    numero: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    libros: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
  });

  useEffect(() => {
    if (newlibroError) {
      setAddError(true);
    }
    if (newlibroSuccess) {
      formik.resetForm();
      formik.setValues(initialValues);
      setAddSuccess(true);
    }
  }, [newlibroError, newlibroSuccess]);
  const { consultorId } = useParams();

  const formik = useFormik({
    initialValues,
    onSubmit: async values => {
      // onSubmit: values => {
      setAddError(false);
      setAddSuccess(false);
      // eslint-disable-next-line react-hooks/rules-of-hooks

      const formData = new FormData();
      formData.append('nombre_y_apellido', values.nombre);
      formData.append('telefono', values.telefono);
      formData.append('email', values.email);
      formData.append('libros', values.libros);
      formData.append('idconsultor', consultorId ? consultorId.split('-')[0] : '0');
      formData.append('calle', values.calle);
      formData.append('numero', values.numero);
      formData.append('piso', values.piso);
      formData.append('localidad', values.localidad);
      formData.append('partido', values.partido);
      formData.append('provincia', values.provincia);
      formData.append('codigo_postal', values.codigopostal);
      formData.append('pais', 'ARG');
      if (values.archivo) {
        formData.append('archivo', values.archivo);
      }
      // setAddSuccess(true);
      await dispatch(compraLibros(formData));
      //setAddSuccess(true);
    },
    validationSchema: validationSchema,
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <>
      <Container>
        {!addSuccess && (
          <div className="row g-4">
            <strong>DATOS PERSONALES</strong>
            <Form.Group className="col-md-12">
              <label className="form-label">Nombre y Apellido</label>
              <Form.Control
                onBlur={formik.handleBlur}
                isInvalid={formik.errors.nombre ? true : false}
                required
                onChange={formik.handleChange}
                value={formik.values.nombre}
                name="nombre"
              />
              <Form.Control.Feedback type="invalid">{formik.errors.nombre}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="col-md-6">
              <label className="form-label">Telefono</label>
              <Form.Control
                onBlur={formik.handleBlur}
                isInvalid={formik.errors.telefono ? true : false}
                required
                value={formik.values.telefono}
                onChange={formik.handleChange}
                name="telefono"
              />
              <Form.Control.Feedback type="invalid">{formik.errors.telefono}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="col-md-6">
              <label className="form-label">Email</label>
              <Form.Control
                onBlur={formik.handleBlur}
                isInvalid={formik.errors.email ? true : false}
                required
                value={formik.values.email}
                onChange={formik.handleChange}
                name="email"
              />
              <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
            </Form.Group>
            <label className="form-label">
              <strong>DOMICILIO DE ENTREGA EN ARGENTINA</strong>
            </label>

            <Form.Group className="col-md-6">
              <label className="form-label">Calle</label>
              <Form.Control
                onBlur={formik.handleBlur}
                isInvalid={formik.errors.calle ? true : false}
                required
                value={formik.values.calle}
                onChange={formik.handleChange}
                name="calle"
              />
              <Form.Control.Feedback type="invalid">{formik.errors.calle}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="col-md-3">
              <label className="form-label">Numero</label>
              <Form.Control
                onBlur={formik.handleBlur}
                isInvalid={formik.errors.numero ? true : false}
                required
                value={formik.values.numero}
                onChange={formik.handleChange}
                name="numero"
              />
              <Form.Control.Feedback type="invalid">{formik.errors.numero}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col-md-3">
              <label className="form-label">Piso/Depto/Lote/Unidad</label>
              <Form.Control
                onBlur={formik.handleBlur}
                isInvalid={formik.errors.piso ? true : false}
                required
                value={formik.values.piso}
                onChange={formik.handleChange}
                name="piso"
              />
              <Form.Control.Feedback type="invalid">{formik.errors.piso}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="col-md-3">
              <label className="form-label">Localidad</label>
              <Form.Control
                onBlur={formik.handleBlur}
                isInvalid={formik.errors.localidad ? true : false}
                required
                value={formik.values.localidad}
                onChange={formik.handleChange}
                name="localidad"
              />
              <Form.Control.Feedback type="invalid">{formik.errors.localidad}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col-md-3">
              <label className="form-label">Partido</label>
              <Form.Control
                onBlur={formik.handleBlur}
                isInvalid={formik.errors.partido ? true : false}
                required
                value={formik.values.partido}
                onChange={formik.handleChange}
                name="partido"
              />
              <Form.Control.Feedback type="invalid">{formik.errors.partido}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="col-md-3">
              <label className="form-label">Provincia</label>
              <Form.Control
                onBlur={formik.handleBlur}
                isInvalid={formik.errors.provincia ? true : false}
                required
                value={formik.values.provincia}
                onChange={formik.handleChange}
                name="provincia"
              />
              <Form.Control.Feedback type="invalid">{formik.errors.provincia}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col-md-3">
              <label className="form-label">Codigo Postal</label>
              <Form.Control
                onBlur={formik.handleBlur}
                isInvalid={formik.errors.codigopostal ? true : false}
                required
                value={formik.values.codigopostal}
                onChange={formik.handleChange}
                name="codigopostal"
              />
              <Form.Control.Feedback type="invalid">{formik.errors.codigopostal}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col-md-12">
              <label className="form-label">
                <strong>NOMBRE DE LOS LIBROS QUE DESEA ADQUIRIR Y CANTIDAD</strong>
              </label>
              <Form.Control
                onBlur={formik.handleBlur}
                isInvalid={formik.errors.libros ? true : false}
                required
                as="textarea"
                rows={6}
                maxLength={2000}
                value={formik.values.libros}
                onChange={formik.handleChange}
                name="libros"
              />
              <Form.Control.Feedback type="invalid">{formik.errors.libros}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="col-md-12">
              <label className="form-label">
                <strong>ADJUNTAR COMPROBANTE DE PAGO {'   '}</strong>
                (tamaño máximo de archivo: 5MB)
              </label>
              <Form.Control
                type="file"
                accept=".jpg,.jpeg,.png,.pdf,.zip"
                onChange={event => {
                  const target = event.currentTarget as HTMLInputElement;
                  if (target.files && target.files.length > 0) {
                    const file = target.files[0];
                    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

                    if (file.size > MAX_FILE_SIZE) {
                      formik.setFieldError('archivo', 'El archivo no debe superar los 5MB');
                      target.value = '';
                      return; // No asignamos el archivo si es demasiado grande
                    }

                    formik.setFieldValue('archivo', file);
                    formik.setFieldError('archivo', ''); // Limpiar error si el archivo es válido
                  }
                }}
              />
              {formik.errors.archivo && <div className="text-danger">{formik.errors.archivo}</div>}

              <label className="form-label">
                <br />
                <strong>IMPORTANTE: </strong>
                <br />
                Si no logras adjuntar el comporbante, no olvides enviármelo por email a {dataConsultor?.Email} o Whatsapp al{' '}
                {dataConsultor?.Telefono} e indicarme cual es el nombre del libro adquirido.
                <br />
                <strong>SON TRES COSAS: TU NOMBRE COMPLETO, COMPROBANTE DE TRANSFERENCIA Y NOMBRE DEL LIBRO</strong>
              </label>
            </Form.Group>

            <div className="col d-grid mb-5">
              {newlibroLoading && <Button className="btn btn-primary">Aguarde un instante...</Button>}
              {!newlibroLoading && (
                <Button className="btn btn-primary" onClick={() => formik.handleSubmit()}>
                  Enviar
                </Button>
              )}
            </div>
          </div>
        )}
        {addSuccess && (
          <div className="col-lg-12">
            <div className="card mb-5">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <h6 className="text-secondary">Tu formuliaro fue enviado con exito</h6>

                    <p className="mt-5">
                      Ante cualquier duda, ponete en contacto con el consultor, quien enviará tu pedido a Humano Puente.{' '}
                    </p>
                    <p>Luego, en el lapso de 72 hs hábiles, deberías recibir un email de Humano Puente para acordar el envío.</p>
                    <p>ENVÍOS EXCLUSIVOS PARA LA REPÚBLICA ARGENTINA</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {addError && (
          <Alert variant="danger" dismissible onClose={() => setAddError(false)}>
            <Alert.Heading>¡Ups, algo salió mal!</Alert.Heading>
            <p>Intenta nuevamente</p>
          </Alert>
        )}
      </Container>
    </>
  );
};

export default LibroForm;
